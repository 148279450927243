<template>
  <out-layout>
    <div class="about" slot="form-content">
      <h1>This is an about page</h1>
    </div>
  </out-layout>
</template>

<script>
import OutLayout from '@/layouts/OutLayout.vue';

export default {
  components: {
    OutLayout,
  },
};
</script>
