<template>
  <section class="out-layout">
    <div class="content">
      <ima-logo type="dark" />

      <v-card class="elevation-10 card-form">
        <slot name="form-header" />
        <slot name="form-content" />
      </v-card>

      <nav class="navigation">
        <slot name="navigation" />
      </nav>
    </div>
  </section>
</template>

<script>
import ImaLogo from '@/components/base/ImaLogo.vue';
import MtrBrand from '@/components/base/MtrBrand.vue';

export default {
  name: 'OutLayout',
  components: {
    MtrBrand,
    ImaLogo,
  },
};
</script>

<style lang="scss">
.out-layout {
  & {
    display: grid;
    height: 100vh;
    background: #f5f5f5 url("../assets/waves-grey-background.png") bottom no-repeat;
    background-size: cover;
  }

  > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ima-logo {
    margin-bottom: 2rem;
    max-width: 80px;
  }

  .card-form {
    padding: 2rem 2rem 1rem;
    max-width: 460px;

    .header {
      text-align: center;
    }

    .action {
      justify-content: space-around;
    }
  }

  .navigation {
    & {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
    }

    > .link {
      text-transform: none;
    }
  }
}
</style>
