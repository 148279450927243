var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "out-layout" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("ima-logo", { attrs: { type: "dark" } }),
        _c(
          "v-card",
          { staticClass: "elevation-10 card-form" },
          [_vm._t("form-header"), _vm._t("form-content")],
          2
        ),
        _c("nav", { staticClass: "navigation" }, [_vm._t("navigation")], 2),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }