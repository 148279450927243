var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("out-layout", [
    _c(
      "div",
      {
        staticClass: "about",
        attrs: { slot: "form-content" },
        slot: "form-content",
      },
      [_c("h1", [_vm._v("This is an about page")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }